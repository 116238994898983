@charset "utf-8";
/*CSS Document*/
/*init BEGIN*/
body,h1,h2,h3,h4,h5,h6,hr,p,blockquote,/*structural elements 结构元素*/ dl,dt,dd,ul,ol,li,/*list elements 列表元素*/ pre,/*text formatting elements 文本格式元素*/ fieldset,lengend,button,input,textarea,/*form elements 表单元素*/ th,td{margin:0; padding:0; outline:none;}/*清除内外边距*/
a:focus{-moz-outline-style:none;}/*IE不支持*/
a:focus{outline:none;}/*很多网站都会加的属性*/
body,button,input,select,textarea{/*for ie*//*font:12px/1 Tahoma,Helvetica,Arial,"宋体",sans-serif;*/font:12px/1 Tahoma,Helvetica,Arial,"\5b8b\4f53",sans-serif; /*用 ascii 字符表示，使得在任何编码下都无问题*/}/*设置默认字体*/
address,cite,dfn,em,var{font-style:normal;}/*将斜体扶正*/
code,kbd,pre,samp,tt{font-family:"Courier New",Courier,monospace;}/*统一等宽字体*/
small{font-size:12px;}/*小于 12px 的中文很难阅读，让 small 正常化*/
ul,ol{list-style:none;}/*重置列表元素*/
a{text-decoration:none;}/*重置文本格式元素*/
abbr[title],acronym[title]{/*注：1.ie6 不支持 abbr; 2.这里用了属性选择符，ie6 下无效果*/border-bottom:1px dotted; cursor:help;}
q:before,q:after{content:'';}
/*重置表单元素*/
legend{color:#000;}/*for ie6*/fieldset,img{border:none;}/*img 搭车：让链接里的 img 无边框*/
/*注：optgroup 无法扶正*/
button,input,select,textarea{font-size:100%; /*使得表单元素在 ie 下能继承字体大小*/}
table{border-collapse:collapse; border-spacing:0;}/*重置表格元素*/
hr{border:none; height:1px;}/*重置 hr*/
html{overflow-y:scroll;}/*让非ie浏览器默认也显示垂直滚动条，防止因滚动条引起的闪烁*/
    /*清除float BEGIN*/
.clearfix:after{content:"."; display:block; height:0; clear:both; visibility:hidden;}
.clearfix{display:inline-block; display:block; zoom:1;}
    /*清除float END*/
/*init END*/
/*banner BEGIN*/
.cg_banner{ width:100%; overflow:hidden; position:relative;}
   /*子页banner高度*/
.cg_banner,
.cg_banner_listbox,
.cg_banner_listbox ul,
.cg_banner_listbox ul li,
.cg_banner_listbox ul li a{ display:block; height:205px;}
  /*首页banner高度*/
.cg_banner.cg_index_banner,
.cg_index_banner .cg_banner_listbox,
.cg_index_banner .cg_banner_listbox ul,
.cg_index_banner .cg_banner_listbox ul li,
.cg_index_banner .cg_banner_listbox ul li a{ display:block; height:450px;}

.cg_banner_listbox{ position:relative; height:100%; z-index:0;}
.cg_banner_listbox ul li{zoom:1; vertical-align:middle;}
    /*li按钮*/
.cg_banner .hd{ height:15px; overflow:hidden; position:absolute; right:0; bottom:5px; z-index:1; width:100%; }
.cg_banner .hd ul{ overflow:hidden; zoom:1; clear:both; margin:0 auto;}
.cg_banner .hd ul li{ float:left; margin:0 2px;  width:30px; height:8px; line-height:8px; text-align:center; background:#fff; cursor:pointer; text-indent:-99px; overflow:hidden;}
.cg_banner .hd ul li.on{ background:#f00; color:#fff; }
    /*前/后按钮代码*/
.cg_banner .prev,
.cg_banner .next{ position:absolute; left:3%; top:50%; margin-top:-25px; display:block; width:32px; height:40px; background:url(../images/slider-arrow.png) -110px 5px no-repeat; filter:alpha(opacity=50);opacity:0.5; z-index:9;}
.cg_banner .next{ left:auto; right:3%; background-position:8px 5px; }
.cg_banner .prev:hover,
.cg_banner .next:hover{ filter:alpha(opacity=100);opacity:1;  }
.cg_banner .prevStop{ display:none;}
.cg_banner .nextStop{ display:none;}
/*banner END*/
/*上下篇 BEGIN*/
.sub_updown{clear:both; font-family:"微软雅黑"; overflow:hidden; font-size:12px; padding:4px 0; width:100%;}
.sub_updown .sub_updown_up,.sub_updown .sub_updown_down{clear:both; overflow:hidden; margin:6px 0px;}
.sub_updown div a{color:#666;}
.sub_updown .sub_updown_hover a:hover{color:#06F;}
.sub_updown .sub_updown_hover a span{ cursor:pointer;}
/*上下篇 END*/
/*分页 BEGIN*/
.ql_fenye{clear:both; width:100%; text-align:center; font-family:"微软雅黑"; font-size:12px;}
.paginator a{color:#444;padding:2px 6px;border:1px solid #ccc;}
.paginator a,.paginator span{margin:0px 3px;}
.paginator a.sun_paging_hover:hover{color:#F00;}
.paginator span{border:1px solid #ccc !important; color:#F00 !important; padding:2px 6px !important; font-weight:normal !important;}/*选中需要全部important一下*/
/*分页 END*/
/*百度地图*/
.iw_poi_title {color:#CC5522;font-size:14px;font-weight:bold;overflow:hidden;padding-right:13px;white-space:nowrap;}
.iw_poi_content {font:12px arial,sans-serif;overflow:visible;padding-top:4px;white-space:-moz-pre-wrap;word-wrap:break-word;}
a[href] *{ cursor:pointer;}
a[href] img:hover{ filter:alpha(opacity=80); opacity:0.8; -moz-opacity:0.8;}
/*
{display:inline-block; *zoom:1; *display:inline;}
IE7兼容 display: inline-block










*/




