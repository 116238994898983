/* CSS Document */
@import (less) 'base.css';/*.css*/
/*@import 'css'; less*/
/*以下为公用样式，不可以修改，如涉及到参数请在html页面删除相应的class或id*/
body {
	font-size: 12px;
	line-height: 26px;
	font-family: "微软雅黑";
}
.cg_fs {
	font-family: "宋体";
}
.cg_l {
	float: left;
}
.cg_r {
	float: right;
}
/*width*/
.w1002, .w1004, .w1100, .w1200, .w1216, .w1280 {
	margin: 0 auto;
}
.w1002 {
	width: 1002px;
}
.w1004 {
	width: 1004px;
}
.w1100 {
	width: 1100px;
}
.w1200 {
	width: 1200px;
}
.w1216 {
	width: 1216px;
}
.w1280 {
	width: 1280px;
}
/*height*/
.cg_height5 {
	height: 5px;
}
.cg_height10 {
	height: 10px;
}
.cg_height15 {
	height: 15px;
}
.cg_height20 {
	height: 20px;
}
.cg_height25 {
	height: 25px;
}
.cg_height30 {
	height: 30px;
}
.cg_height35 {
	height: 35px;
}
.cg_height40 {
	height: 40px;
}
.cg_height45 {
	height: 45px;
}
.cg_height50 {
	height: 50px;
}
.cg_height55 {
	height: 55px;
}
.cg_height60 {
	height: 60px;
}
.cg_height65 {
	height: 65px;
}
.cg_height70 {
	height: 70px;
}
.cg_height75 {
	height: 75px;
}
.cg_height80 {
	height: 80px;
}
.cg_height85 {
	height: 85px;
}
.cg_height90 {
	height: 90px;
}
.cg_height95 {
	height: 95px;
}
.cg_height100 {
	height: 100px;
}
.cg_height105 {
	height: 105px;
}
.cg_height110 {
	height: 110px;
}
.cg_height115 {
	height: 115px;
}
.cg_height120 {
	height: 120px;
}
.cg_height125 {
	height: 125px;
}
.cg_height130 {
	height: 130px;
}
.cg_height135 {
	height: 135px;
}
.cg_height140 {
	height: 140px;
}
.cg_height145 {
	height: 145px;
}
.cg_height150 {
	height: 150px;
}
/*margin*/
.cg_margin_rightno {
	margin-right: 0 !important;
}
.cg_margin_topno {
	margin-top: 0 !important;
}
.cg_margin_bottomno {
	margin-bottom: 0 !important;
}
.cg_margin_leftno {
	margin-left: 0 !important;
}
/*padding*/
.cg_padding_leftno {
	padding-left: 0 !important;
}
.cg_padding_rightno {
	padding-right: 0 !important;
}
.cg_padding_topno {
	padding-top: 0 !important;
}
.cg_padding_bottomno {
	padding-bottom: 0 !important;
}
/*border*/
.cg_border_topno {
	border-top: none !important;
}
.cg_border_leftno {
	border-left: none !important;
}
.cg_border_rightno {
	border-right: none !important;
}
.cg_border_bottomno {
	border-bottom: none !important;
}
.cg_back_no {
	background: none !important;
}
/*以上为公用样式，不可以修改，如涉及到参数请在html页面删除相应的class或id*/
/*top BEGIN*/
.ql_top {
	clear: both;
	height: 34px;
	background: #f2f2f2;
}
.ql_topbox {
	height: 34px;
 .w1200;
}
.ql_top_left {
	float: left;
	height: 34px;
	line-height: 34px;
	font-size: 12px;
	color: #666666;
	padding-left: 26px;
	background: url(../images/bg_03.png) no-repeat left center;
 strong {
font-size:14px;
color:#666666;
}
}
.ql_top_rigth {
	float: right;
	height: 34px;
	line-height: 34px;
}
.ql_clock {
	height: 34px;
	font-size: 12px;
	color: #12px;
	color: #666666;
	float: left;
	background: url(../images/bg_02.png) no-repeat left center;
	padding-left: 25px;
	padding-right: 10px;
}
.ql_top_rigth a {
	float: left;
	display: block;
	height: 34px;
	line-height: 34px;
	width: 24px;
	margin-right: 7px;
}
.ql_top_rigtha {
	background: url(../images/bg_01.png) no-repeat left bottom;
 &:hover {
background:url(../images/bg_01.png) no-repeat left top
}
}
.ql_top_rigthb {
	background: url(../images/bg_01.png) no-repeat -31px bottom;
 &:hover {
background:url(../images/bg_01.png) no-repeat -31px top
}
}
.ql_top_rigthc {
	background: url(../images/bg_01.png) no-repeat right bottom;
	position: relative;
 &:hover {
background:url(../images/bg_01.png) no-repeat right top
}
span {
	position: absolute;
	top: 0;
	right: 24px;
	display: block;
	height: 34px;
	width: 150px;
	padding: 0 10px;
	background: #abedce;
	display: none;
 input {
width:100%;
height:34px;
line-height:34px;
font-size:14px;
color:#333;
border:none;
background:none;
font-family:"微软雅黑";
cursor:auto;
}
}
}
.ql_nav {
	height: 98px;
 li {
background: url(../images/bg_04.png) no-repeat right center;
}
}
.ql_nav_log {
	float: left;
	padding-top: 20px;
	width: 264px;
	background: url(../images/logoright.png) no-repeat right center;
	height: 78px;
}
.ql_nav_ul {
	float: right;
	height: 98px;
 li, li>a {
float:left;
width:118px;
text-align:center;
height:98px;
line-height:98px;
}
li>a {
	font-size: 14px;
	color: #8a8a8a;
 &:hover {
color:#257eec;
}
}
li>a.on {
	color: #257eec;
}
}
/*top END*/
/*bottom BEGIN*/
.ql_bottom {
	clear: both;
	background: #dcdcdc;
}
.ql_bottombox {
	min-height: 92px;
	padding: 13px 0;
}
.ql_bottom_ewm {
	float: right;
}
.ql_bottom_nav {
	font-size: 14px;
	color: #b5b5b5;
	height: 38px;
	line-height: 38px;
 a {
color:#000000;
 &:hover {
color:#666666;
}
}
}
.ql_bottom_lx, .ql_bottom_yq {
	font-size: 12px;
	color: #666666;
	line-height: 24px;
a {
color:#666666;
&:hover {
color:#000;
}
}
}
.ql_bot_copy {
	background: #acacac;
}
.ql_bot_copybox {
	text-align: center;
	min-height: 24px;
	line-height: 24px;
	font-size: 12px;
	color: #565656;
	padding: 11px 0;
 a {
color:#565656;
&:hover {
color:#fff;
}
}
img {
	vertical-align: middle;
}
}
.bot_img {
	padding-right: 5px;
}
.ql_subpag_left {
	float: left;
	width: 235px;
	background: #00a458 url(../images/left_02.png) no-repeat bottom right;
	min-height: 160px;
}
/*bottom END*/
/*left BEGIN*/
.ql_left_top {
	height: 42px;
	line-height: 42px;
	padding-top: 32px;
	font-size: 22px;
	color: #ffffff;
	padding-left: 18px;
 em {
font-size:15px;
text-transform:uppercase;
}
}
.ql_left_boredr {
	width: 196px;
	height: 1px;
	margin: 0 auto;
	border-top: 1px solid #dcdcdc;
	border-bottom: 1px solid #dcdcdc;
}
.ql_left_list {
	padding: 15px 18px 90px 18px;
 h2, h2 a {
display:block;
height:46px;
line-height:46px;
font-size:16px;
color:#ffffff;
font-weight:normal;
}
h2 a {
	padding-left: 41px;
 &:hover {
background:url(../images/left_01.png) no-repeat left center;
}
}
li {
	padding-bottom: 1px;
}
li:hover {
	border-bottom: 1px solid #dcdcdc;
	padding-bottom: 0;
}
li.on {
	border-bottom: 1px solid #dcdcdc;
	padding-bottom: 0;
h2 a {
background:url(../images/left_01.png) no-repeat left center;
}
}
}
.ql_left_list li {
 div {
min-height:20px;
display:none;
 a {
display:block;
height:36px;
line-height:36px;
padding-left:40px;
background:url(../images/left_03.png) no-repeat 12px center;
font-size:14px;
color:#ffffff;
 &:hover {
background:url(../images/left_04.png) no-repeat 12px center;
}
}
a.on {
background:url(../images/left_04.png) no-repeat 12px center;
}
}
}
.ql_left_list li div a.on {
}
/*.ql_left_list li.on div{ display:block !important;}*/
/*left END*/
/*right BEGIN*/
.ql_subpag {
	padding-top: 26px;
	padding-bottom: 60px;
}
.ql_subpag_right {
	float: right;
	width: 928px;
}
.ql_right_top {
	height: 26px;
	line-height: 26px;
	font-size: 12px;
	color: #999999;
	border-bottom: 1px dashed #efefef;
a {
color:#999999;
&:hover {
color:#00a458;
}
}
>div {
padding-left:20px;
background:url(../images/weizhi.png) no-repeat left center;
}
}
.ql_subpag_main {
	min-height: 440px;
	padding: 35px 0 0 0;
}
/*right END*/
/*sitemap BEGIN*/
.ql_sitemap {
	text-align: center;
	min-height: 125px;
}
.ql_sitemapul {
	background: url(../images/sitemap.jpg) repeat-x top;
	padding: 0 54px;
 li {
float:left;
width:117px;
text-align:center;
 div {
height:36px;
line-height:36px;
font-size:14px;
background:url(../images/bg_04.png) no-repeat right 16px;
padding-bottom:15px;
}
div a {
color:#333333;
&:hover {
color:#00a458;
font-weight:bold;
}
}
}
li em {
	display: block;
	min-height: 20px;
	line-height: 20px;
	padding: 7px 2px;
 a {
color:#666666;
font-size:14px;
 &:hover {
color:#00a458;
}
}
}
}
/*sitemap END*/
/*联系方式 BEGIN*/
.ql_lxfstop_le {
	float: left;
	width: 455px;
	font-size: 15px;
	color: #666666;
	line-height: 32px;
	padding-top: 115px;
	background: url(../images/lxfs.png) no-repeat left top;
}
/*联系方式 END*/
/*投诉建议 BEGIN*/
.ql_tsjy {
	line-height: 36px;
	padding-bottom: 26px;
}
.ql_tsjya {
	font-size: 18px;
	color: #bd0000;
	font-weight: bold;
}
.ql_tsjyb {
	font-size: 15px;
	color: #bd0000;
}
.ql_tsjyin_box {
	background: url(../images/tsjy02.png) no-repeat 608px 43px;
}
.ql_tsjyin_bt {
	display: block;
	float: left;
	height: 34px;
	line-height: 34px;
	font-size: 14px;
	color: #333333;
}
.ql_tsjyin, .ql_tsjytext {
	padding-bottom: 23px;
}
.ql_tsjyin div {
	float: left;
	height: 34px;
	width: 442px;
	padding: 0 10px;
	border: 1px solid #dcdcdc;
input {
width:100%;
height:34px;
line-height:34px;
font-size:14px;
color:#333333;
border:none;
background:none;
font-family:"微软雅黑";
}
}
.ql_tsjyinhx {
	float: left;
	display: block;
	height: 34px;
	width: 38px;
	background: url(../images/tsjy01.png) no-repeat right 5px;
}
.ql_tsjytext div {
	float: left;
	width: 442px;
	padding: 5px 10px;
	border: 1px solid #dcdcdc;
	height: 150px;
 textarea {
height:150px;
width:100%;
line-height:22px;
border:none;
background:none;
resize:none;
font-size:14px;
color:#333333;
font-family:"微软雅黑";
}
}
.ql_tsjybtn {
	min-height: 38px;
	padding-top: 55px;
	padding-left: 332px;
a {
 float:left;
display:block;
height:38px;
line-height:38px;
width:92px;
text-align:center;
color:#fff;
background:#999999;
font-size:18px;
margin-right:18px;
}
}
/*投诉建议 END*/
/*欧通能源 BEGIN*/
.ql_about_gjc {
	font-size: 14px;
	line-height: 32px;
	color: #333333;
a {
color:#00a458;
font-weight:bold;
&:hover {
color:#1d5ba6;
}
}
}
.ql_about_top {
	min-height: 75px;
	padding-bottom: 14px;
em {
display:block;
height:50px;
line-height:50px;
font-size:36px;
color:#1d5ba6;
}
div {
	height: 34px;
	line-height: 34px;
	font-size: 22px;
	text-transform: uppercase;
	color: #e5e4e4;
}
}
.ql_paddimgtop_15 {
	padding-top: 15px !important;
}
/*欧通能源 END*/
/*公司战略 BEGIN*/
.ql_gszl_bt {
	line-height: 38px;
	font-size: 20px;
	color: #1d5ba6;
}
.ql_gszl_wz {
	line-height: 30px;
	font-size: 14px;
	color: #333333;
}
.ql_gszl_imgrigth {
	padding: 49px 0 0 0;
}
/*公司战略 END*/
/*大事记 BEGIN*/
.ql_dsjlist {
	min-height: 300px;
li {
min-height:26px;
line-height:26px;
padding:18px 0;
border-bottom:1px dashed #999999;
h3 {
font-size:16px;
font-weight:normal;
min-height:26px;
padding-left:34px;
background:url(../images/dsj.png) no-repeat left 4px;
}
}
}
/*大事记 END*/
/*BEGIN*/
.q_organization {
	text-align: center;
}
.mod18Box {
	margin: 0px auto;
}
.mod18 {
	margin: 0px auto;
	position: relative;
	overflow: hidden;
}
.mod18 .btn {
	position: absolute;
	width: 18px;
	height: 40px;
	bottom: 42px;
	cursor: pointer;
	z-index: 99;
	font-size: 50px;
	font-weight: bold;
	background: url(../images/prevBtn.png) no-repeat 0 0;
}
.mod18 .prev {
	left: 0;
}
.mod18 .prev:hover {
	background: url(../images/prevBtn2.png) no-repeat 0px 0px;
}
.mod18 .next:hover {
	background: url(../images/prevBtn2.png) no-repeat 0px 0px;
}
.mod18 #prevTop, .mod18 #nextTop {
	top: 140px;
	width: 46px;
	height: 48px;
	display: none;
}
.mod18 #prevTop {
	background: url(../images/prevBtnTop.png) 0 0 no-repeat;
}
.mod18 #nextTop {
	background: url(../images/nextBtnTop.png) 0 0 no-repeat;
}
.mod18 .next {
	right: 0;
	background-position: right 0;
}
.mod18 li {
	float: left;
}
.mod18 .q_cf li {
	position: relative;
	color: #fff;
}
.mod18 .q_cf>a {
	display: block;
	width: 360px;
	height: 225px;
	color: #fff;
}
.mod18 .picBox {
	height: 562px;
	position: relative;
	overflow: hidden;
}
.mod18 .picBox ul {
	height: 562px;
	position: absolute;
}
.mod18 .picBox li {
	width: 928px;
	height: 498px;
	text-align: center;
	position: relative;
}
.mod18 .picBox li h3 {
	font-size: 14px;
	color: #000000;
}
.mod18 .picBox li>a {
	display: block;
}
.mod18 .listBox {
	width: 842px;
	height: 130px;
	margin: 0 auto;
	position: relative;
	overflow: hidden;
}
.mod18 .listBox ul {
	height: 100px;
	position: absolute;
}
.mod18 .listBox li {
	width: 164px;
	height: 125px;
	cursor: pointer;
	position: relative;
	padding-right: 5px;
}
.mod18 .listBox li i {
	display: none;
}
.mod18 .listBox li a {
	display: block;
	width: 124px;
	height: 70px;
}
.mod18 .listBox li img {
	border: 2px solid #fff;
}
.mod18 .listBox .on img {
	border: 2px solid #8e0000;
	border-radius: 2px;
}
.mod18 .listBox li:hover img {
	border: 2px solid #8e0000;
	border-radius: 2px;
}
.mod18 .listBox .on i {
	display: block;
}
.picBox_cont {
	padding: 20px;
	background: #e6e6e6;
}
.picBox_cont h3 a {
	font-size: 18px;
	font-weight: bold;
	color: #333;
}
.q_cf_title {
	display: block;
	height: 46px;
	line-height: 46px;
	font-size: 14px;
}
.picBox_cont>span {
	color: #999;
	font-family: "宋体";
	font-size: 12px;
}
.picBox_cont>div {
	color: #333;
	line-height: 28px;
	height: 84px;
	overflow: hidden;
}
.q_honorpa {
	padding-top: 20px;
}
.q_cf_nr {
	position: absolute;
	bottom: 0px;
	height: 48px;
	line-height: 48px;
	overflow: hidden;
	padding: 5px 22px;
	background: url(../images/q_cf_nr.png) repeat;
	width: 706px;
	left: 89px;
	text-align: left;
}
/*END*/
/*新闻资讯 BEGIN*/
.ql_xwzxt {
	height: 164px;
	clear: both;
>a {
display:block;
float:left;
}
}
.ql_xwzxta {
	margin-left: 273px;
}
.ql_xwzxta_a {
	height: 42px;
em {
float:right;
display:block;
height:42px;
line-height:42px;
font-size:12px;
color:#333333;
padding-left:22px;
background:url(../images/shijian2.png) no-repeat left center;
}
h3 {
	display: block;
	height: 42px;
	line-height: 42px;
	font-size: 18px;
	font-weight: normal;
 a {
color:#333333;
 &:hover {
color:#01479d;
}
}
}
}
.ql_xwzxta_a:hover em {
	background: url(../images/shijian.png) no-repeat left center;
}
.ql_xwzxta_b {
	font-size: 14px;
	line-height: 28px;
	height: 56px;
	color: #333333;
}
.ql_xwzxta_c {
	height: 28px;
a {
 float:right;
display:block;
height:28px;
line-height:28px;
width:130px;
text-align:center;
background:#01479d;
color:#fff;
font-size:14px;
&:hover {
background:#00a458;
}
}
}
.ql_xwzx_list {
	min-height: 350px;
	padding-bottom: 33px;
}
.ql_xwzx_list li {
	height: 194px;
	clear: both;
	border-bottom: 1px solid #dddddd;
	padding-top: 10px;
h3 {
height:64px;
line-height:64px;
font-size:18px;
font-weight:normal;
a {
color:#333333;
&:hover {
color:#01479d;
}
}
}
}
.ql_xwzx_more {
	height: 28px;
	padding-top: 22px;
	clear: both;
a {
display:block;
height:28px;
line-height:28px;
width:132px;
text-align:center;
font-size:14px;
color:#fff;
background:#b2b2b2;
&:hover {
background:#00a458;
}
}
}
/*详细页*/
.ql_xwzxhbt {
	text-align: center;
	min-height: 42px;
	line-height: 42px;
	font-size: 18px;
	font-weight: bold;
	color: #01479d;
}
.ql_ztdzx {
	height: 34px;
	padding-bottom: 21px;
	line-height: 34px;
	text-align: center;
	font-size: 12px;
	color: #757575;
	text-transform: uppercase;
a {
color:#757575;
&:hover {
color:#00a458;
}
}
}
.ql_xwzx_textxx {
	font-size: 14px;
	color: #333333;
	line-height: 28px;
	padding-bottom: 20px;
	min-height: 320px;
}
/*新闻底部*/
.ql_xwzx_botbt {
	height: 38px;
	clear: both;
	padding-top: 54px;
em {
display:block;
padding-left:32px;
height:38px;
line-height:38px;
font-size:14px;
color:#ffffff;
background:url(../images/xwzx_01.jpg) no-repeat left center;
}
}
.ql_xwzx_botul {
	padding-top: 17px;
	height: 123px;
li {
 float:left;
 width:415px;
 margin-right:98px;
height:40px;
border-bottom:1px dotted #cccccc;
em {
display:block;
height:40px;
line-height:40px;
float:right;
font-size:12px;
color:#666666;
}
}
}
.ql_xwzx_botul li {
 h3 {
height:40px;
line-height:40px;
font-size:14px;
color:#333333;
font-weight:normal;
margin-right:78px;
padding-left:28px;
background:url(../images/xwzx_02.jpg) no-repeat left center;
 a {
color:#333333;
 &:hover {
color:#01479d;
}
}
}
}
/*新闻资讯 END*/
/*经营范围 BEGIN*/
.ql_jyfwbto {
	font-size: 28px;
	color: #1d5ba6;
	line-height: 58px;
}
/*经营范围 END*/
/*技术优势 BEGIN*/
.ql_jsys {
	min-height: 380px;
	padding-bottom: 50px;
li {
position:relative;
z-index:10;
float:left;
width:295px;
height:248px;
overflow:hidden;
margin-top:24px;
margin-right:21px;
h3 {
 width:100%;
position:absolute;
left:0;
bottom:0;
z-index:5;
height:36px;
line-height:36px;
font-weight:normal;
font-size:16px;
color:#ffffff;
text-align:center;
background:url(../images/jsys.png) repeat;
}
}
}
.ql_jsysa {
	position: absolute;
	left: 0;
	top: -250px;
	z-index: 9;
	display: block;
	height: 248px;
	width: 295px;
	background: url(../images/jsys02.png) no-repeat center;
	font-size: 24px;
	color: #ffffff;
	text-align: center;
	line-height: 248px;
}
.ql_jsys_textxx {
	min-height: 50px;
	padding: 20px 0;
	font-size: 14px;
	line-height: 28px;
	color: #333;
}
/*技术优势  END*/
/*BEGIN*/
.ql_rclnright {
	padding-top: 63px;
}
.ql_rclnleft {
	float: left;
	width: 419px;
	background: #f5f5f5;
	min-height: 353px;
	padding: 63px 34px 0px 34px;
}
.ql_rclnleft1 {
	height: 55px;
	line-height: 55px;
	padding-top: 38px;
	font-size: 38px;
	color: #1d5ba6;
}
.ql_rclnleft2 {
	height: 69px;
	line-height: 44px;
	font-size: 24px;
	color: #333333;
}
.ql_rclnleft3 {
	font-size: 14px;
	line-height: 30px;
	color: #666666;
}
/*END*/
/*BEGIN*/
.ql_zptzlist {
	min-height: 300px;
	padding-bottom:30px;
li {
min-height:26px;
line-height:26px;
padding:18px 0;
border-bottom:1px dashed #999999;
em{ float:right; display:block; height:30px; line-height:30px; font-size:12px; color:#333333; padding-left:20px; background:url(../images/shijian2.png) no-repeat left center;}
h3 {
font-size:14px;
font-weight:normal;
min-height:26px;
padding-left:30px;
background:url(../images/dsj.png) no-repeat left 4px;
margin-right:90px;
a {
color:#333333;
&:hover {
color:#00a458;
}
}
}
}
}
/*END*/